/*eslint-disable*/ 
import React from "react";
import Layout from 'components/layout'
import Seo from 'components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles";
import { Link, graphql } from "gatsby"
import Image from "gatsby-image";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Typography from '@material-ui/core/Typography'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";

// kit
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";

import Primary from "components/Typography/Primary.js";

import shingenStyle from "style/kit_shingenStyle.js";

// img
import logo_l from "images/logo_l.svg"
import sorry from "images/sorry.png"

const useStyles = makeStyles(shingenStyle);

const BlogIndex = ({ data }) => {
  const classes = useStyles();
  const siteTitle = "【豊橋・豊川】月極駐車場"
  const pageTitle = "【豊橋・豊川】月極駐車場"
  const siteDescription = "申源のオススメする、月極駐車場をご紹介します！"
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout active="2">
      <Seo title={siteTitle} description={siteDescription}/>
      <div>
        <Parallax image={require("images/header_back6.jpg").default} filter="dark" 
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.textCenter} >
                <h1 className={classes.title_2} id="logo_l">
                  <img src={logo_l} alert="有限会社 申源"/>
                </h1>
                <h2 className={classes.h2_subtitle}>{ pageTitle }</h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div className={classes.container}>
              <Primary className={classes.description}>
                {siteDescription}
              </Primary>
              <hr className={classes.hr} />
              <div>
                【車庫証明発行について】<br />
                　事務所に書類をご持参ください（要手数料）。　郵送対応等については電話でお問い合わせください。
              </div>
              <hr className={classes.hr} />
            {(() => {
              if (posts.length === 0) {
                return <p className={classes.not_bukken}>申し訳ございません。<br />ご希望の物件が見つかりません。<br /><img src={sorry} alert=""/></p>
              } else {
                return(

                  <GridContainer className={classes.bukkenList}>
                    <GridItem><h4 className={classes.h4_ornge_back}>豊橋市</h4></GridItem>
                    {posts.map(post => {
                      const title = post.frontmatter.title || post.fields.slug
                      if (post.frontmatter.tags[1] === "豊橋") {
                        return(
                          <GridItem xs={12} md={12} lg={12} xl={6} key={post.fields.slug}>
                            <article
                              className={classes.bukkenList + " post-list-item"}
                              itemScope
                              itemType="http://schema.org/Article"
                            >
                              <Button
                                variant="contained"
                                color="white"
                                href={post.fields.slug}
                              >
                                <GridContainer>
                                  <GridItem xs={12} sm={4} md={3} lg={2} xl={4} className={classes.listImage}>
                                    <Image fluid={post.frontmatter.listimage.childImageSharp.fluid}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={8} md={9} lg={10} xl={8} className={classes.listText}>
                                    <div>
                                      {post.frontmatter.tags.map((tag) => (
                                        <Badge color="warning" key={tag} className={classes.badge}>{tag}</Badge>
                                      ))}
                                    </div>
                                    <Primary><h3><span itemProp="headline">{title}</span></h3></Primary>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: post.frontmatter.description || post.excerpt,
                                      }}
                                      itemProp="description"
                                    />
                                  </GridItem>
                                </GridContainer>
                              </Button>
                            </article>
                          </GridItem>
                        )
                      }
                    })}
                    <GridItem><h4 className={classes.h4_ornge_back}>豊川市</h4></GridItem>
                    {posts.map(post => {
                      const title = post.frontmatter.title || post.fields.slug
                      if (post.frontmatter.tags[1] === "豊川") {
                        return(
                          <GridItem xs={12} md={12} lg={12} xl={6} key={post.fields.slug}>
                            <article
                              className={classes.bukkenList + " post-list-item"}
                              itemScope
                              itemType="http://schema.org/Article"
                            >
                              <Button
                                variant="contained"
                                color="white"
                                href={post.fields.slug}
                              >
                                <GridContainer>
                                  <GridItem xs={12} sm={4} md={3} lg={2} xl={4} className={classes.listImage}>
                                    <Image fluid={post.frontmatter.listimage.childImageSharp.fluid}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={8} md={9} lg={10} xl={8} className={classes.listText}>
                                    <div>
                                      {post.frontmatter.tags.map((tag) => (
                                        <Badge color="warning" key={tag} className={classes.badge}>{tag}</Badge>
                                      ))}
                                    </div>
                                    <Primary><h3><span itemProp="headline">{title}</span></h3></Primary>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: post.frontmatter.description || post.excerpt,
                                      }}
                                      itemProp="description"
                                    />
                                  </GridItem>
                                </GridContainer>
                              </Button>
                            </article>
                          </GridItem>
                        )
                      }
                    })}
                  </GridContainer>
                )

              }
            })()}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {tags: { in:"月極駐車場" }}}){
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          hero{
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          listimage{
            childImageSharp {
              fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

